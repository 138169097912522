<template>
  <div>
    <v-row class="no-print">
      <v-col>
        <v-text-field type="date" v-model="fromDate" label="Vanaf" ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field type="date" v-model="toDate" label="t/m" ></v-text-field>
      </v-col>
      <v-col>
        <v-select
        :items="years"
        v-model="year"
        label="Jaar"
      ></v-select>
      </v-col>
       <v-col>
        <v-select
        :items="quarters"
        v-model="quarter"
        label="Kwartaal"
      ></v-select>
      </v-col>
      <v-col>
        <v-select
        :items="months"
        item-text="text"
        item-value="value"
        v-model="month"
        label="Maand"
      ></v-select>
      </v-col>
      <v-col>
         <v-text-field type="number" v-model="week" label="Week" ></v-text-field>
      </v-col>
      <v-col>
         <v-text-field type="number" v-model="day" label="Dag" ></v-text-field>
      </v-col>
    </v-row>
    <button class="no-print" @click="fetchData">Verzend</button>
    <button class="no-print" @click="reset">Reset</button>
    <button class="no-print" @click="print">Afdrukken</button>
    <v-row>
    <v-col>
    <v-card>
      <v-card-title>Omzet {{company.name}}</v-card-title>
      {{period}}
      <div class="d-flex flex-column align-center">
      <table border="1">
        <tr>
          <th>Debiteuren</th>
          <td>{{ toCurrency(invoiceGrossTotal)}}</td>
          <td></td>
        </tr>
        <template v-for="(vat,index) of VAT" >
        <tr :key="'a'+index">
          <th>Omzet {{vat.name}} BTW tarief</th>
          <td></td>
          <td>{{ toCurrency(VATBase(vat.total,vat._id))}}</td>         
        </tr>
        <tr :key="'b'+index" v-if="vat._id!=0">
         <th>BTW af te dragen {{vat.name}} tarief</th>
          <td></td>
          <td>{{ toCurrency(vat.total)}}</td>          
        </tr>
        </template>     
        <tr>
          <th>Totaal</th>
          <td></td>
          <td>{{ toCurrency(totals)}}</td>
        </tr>
        <tr>
          <th>Verschil</th>
          <td></td>
          <td>{{ toCurrency(diff)}}</td>
        </tr>
      </table>
      <br><br><br><br>
      <table border="1">
        <tr>
          <th>Debiteuren Export</th>
          <td>{{ toCurrency(invoiceGrossTotalExport)}}</td>
          <td></td>
        </tr>
        <tr>
          <th>Verkopen binnen EU</th>
          <td></td>
          <td>{{ toCurrency(exportEU)}}</td>          
        </tr>        
        <tr>
          <th>Verkopen GB</th>
          <td></td>
          <td>{{ toCurrency(exportGB)}}</td>          
        </tr>
        <tr>
          <th>Verkopen buiten EU(zonder GB)</th>
          <td></td>
          <td>{{ toCurrency(exportOutsideEUexcludeGB)}}</td>          
        </tr>
        <tr>
          <th>Totaal</th>
          <td></td>
          <td>{{ toCurrency(totalsExport)}}</td>
        </tr>
        <tr>
          <th>Verschil</th>
          <td></td>
          <td>{{ toCurrency(diffExport)}}</td>
        </tr>
      </table>
      </div>
    </v-card></v-col>
    </v-row>
  </div>
</template>

<script>
import { fetchGET, toCurrency, toDateString } from '../js/functions';
export default {
  name: "revenue",
  data() {
    return {
      fromDate: "",
      toDate: "",
      year: 0,
      quarter: 0,
      month: 0,
      week: 0,
      day: 0,
      invoiceNetTotal: 0,
      invoiceGrossTotal: 0,
      invoiceNetTotalExport: 0,
      invoiceGrossTotalExport: 0,
      VAT: [
        {_id: 21, total: 0, name:"Hoog"},
        {_id: 9, total: 0, name:"Laag"},
        {_id: 0, total: 0, name:"Geen"},
      ],
      years:[
        2019,
        2020,
        2021,
        2022,
        2023
      ],
      quarters:[
        1,2,3,4
      ],
      months:[
  {text:"Januari", value:1},
  {text:"Februari", value:2},
  {text:"Maart", value:3},
  {text:"April", value:4},
  {text:"Mei", value:5},
  {text:"Juni", value:6},
  {text:"Juli", value:7},
  {text:"Augustus", value:8},
  {text:"September", value:9},
  {text:"Oktober", value:10},
  {text:"November", value:11},
  {text:"December", value:12},
],
      exportEU: 0,
      exportGB: 0,
      exportOutsideEUexcludeGB: 0,
    }
  },
  methods: {
    async fetchData() {
      try {
        let obj = {}
      if(this.fromDate&&this.toDate){
        obj.fromDate = this.fromDate
        obj.toDate = this.toDate
      }
      if(this.year)obj.year = parseInt(this.year)
      if(this.year&&this.quarter)obj.quarter = parseInt(this.quarter)
      if(this.year&&this.month)obj.month = parseInt(this.month)
      if(this.year&&this.week)obj.week = parseInt(this.week)
      if(this.year&&this.month&&this.day)obj.day = parseInt(this.day)
      let res = await fetchGET("revenue",obj)
      console.log(res)
      this.invoiceNetTotal = res[0].value[0] ? res[0].value[0].netTotal : 0
      this.invoiceGrossTotal = res[0].value[0] ? res[0].value[0].grossTotal : 0
      this.VAT = res[1].value.length ? res[1].value.sort((a,b)=>b._id-a._id) : [
        {_id: 0, total: 0, name:"Geen"},
        {_id: 9, total: 0, name:"Laag"},
        {_id: 21, total: 0, name:"Hoog"}
      ],
      this.exportEU = res[2].value[0] ? res[2].value[0].netTotal : 0
      this.exportOutsideEUexcludeGB = res[3].value[0] ? res[3].value[0].netTotal: 0
      
      this.exportGB = res[4].value[0] ? res[4].value[0].netTotal: 0
      
      this.invoiceNetTotalExport = res[5].value[0] ? res[5].value[0].netTotal: 0
      console.log(this.exportOutsideEUexcludeGB)
      this.invoiceGrossTotalExport = res[5].value[0] ? res[5].value[0].grossTotal: 0
      console.log(this.invoiceGrossTotalExport)
      } catch(e) {
        console.error(e)
      }
      
  },
  reset(){
this.fromDate= ""
      this.toDate= ""
      this.year= 0
      this.quarter= 0
      this.month= 0
      this.week= 0
      this.day= 0
      this.invoiceNetTotal = 0
      this.invoiceGrossTotal = 0
      this.exportEU = 0
      this.exportOutsideEUexcludeGB = 0
      this.exportGB = 0
      this.invoiceNetTotalExport = 0
      this.invoiceGrossTotalExport = 0
      this.VAT = [
        {_id: 9, total: 0, name:"Laag"},
        {_id: 21, total: 0, name:"Hoog"}]
  },
  print(){
    window.print()
  },
    toCurrency(val){
    return toCurrency(val)
  },
  VATBase(val,vat){
    if(vat==0)return parseFloat(val)
    else return parseFloat(val) / parseInt(vat) * 100 
  }
  },
  computed: {
    period() {
      let period = ""
      if(this.fromDate&&this.toDate)period = `Periode: ${toDateString(this.fromDate)} t/m ${toDateString(this.toDate)}`
      if(this.year)period = `Periode: ${this.year}`
      if(this.year&&this.quarter) period = `Periode: ${this.year}, ${this.quarter}e kwartaal`
      if(this.year&&this.month) period = `Periode: ${this.year}, ${this.months[this.month-1].text}`
      if(this.year&&this.week) period = `Periode: ${this.year}, week: ${this.week}`
      if(this.year&&this.month&&this.day) period = `Periode: ${toDateString(this.year+','+this.month+','+this.day)}`
      return period
    },
    totals(){
      let VAT = this.VAT.reduce((acc,cur)=>{
        if(cur._id==0) return acc + cur.total
        else return acc+cur.total+parseFloat(this.VATBase(cur.total,cur._id))},0)
      let total = VAT
      return total
    },
    totalsExport(){      
      let exportEU= parseFloat(this.exportEU)
      let exportGB= parseFloat(this.exportGB)
      let exportOutsideEUexcludeGB = parseFloat(this.exportOutsideEUexcludeGB)
      let total = exportEU+exportGB+exportOutsideEUexcludeGB
      return total
    },
    diff(){
    return this.totals - this.invoiceGrossTotal
    },
    diffExport(){
    return this.totalsExport - this.invoiceGrossTotalExport
    },
    company(){
    return this.$store.state.settings.company
  }
  }
}
</script>

<style scoped>
button{
  width: 100px;
  height: 50px;
  background: darkblue;
  color: white;
}
input{
  background: white;
}
table{
 text-align: left;
border-collapse: collapse;
}
th{
  width:250px;
}
td{
  width: 150px;
}
</style>